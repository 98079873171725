import { BaseService } from './base.service.js';
import { timeConverter } from '@ai-agent/util';
class KnowledgeBaseQueueService extends BaseService {
    async faissIndexEmbeddings({ userId, kbId }) {
        const res = await this.fetch(`/knowledge-base/queue/faiss-Index-Embeddings?userId=${userId ?? ''}&kbId=${kbId ?? ''}`, {
            method: 'PUT',
        });
        return res;
    }
}
class KnowledgeBaseResourceChunkService extends BaseService {
    async filter(dto) {
        const res = await this.fetch('/knowledge-base/resource/chunk/filter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        res.data = res.data.map(timeConverter);
        return res;
    }
    async patch(chunkId, dto) {
        const { data } = await this.fetch(`/knowledge-base/resource/chunk/${chunkId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(data);
    }
}
class KnowledgeBaseResourceLogService extends BaseService {
    async filter(dto) {
        const res = await this.fetch('/knowledge-base/resource/log/filter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        res.data = res.data.map(timeConverter);
        return res;
    }
}
class KnowledgeBaseResourceService extends BaseService {
    constructor() {
        super(...arguments);
        this.chunk = new KnowledgeBaseResourceChunkService();
        this.log = new KnowledgeBaseResourceLogService();
    }
    async filter(dto) {
        const res = await this.fetch('/knowledge-base/resource/filter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        res.data = res.data.map(timeConverter);
        return res;
    }
    async create(dto) {
        const { data } = await this.fetch('/knowledge-base/resource', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(data);
    }
    async init(rId) {
        const res = await this.fetch(`/knowledge-base/resource/${rId}/init`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return timeConverter(res.data);
    }
    async remove(rId) {
        await this.fetch(`/knowledge-base/resource/${rId}`, {
            method: 'DELETE',
        });
    }
    async patch(rId, dto) {
        const { data } = await this.fetch(`/knowledge-base/resource/${rId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(data);
    }
}
class KnowledgeBaseService extends BaseService {
    constructor() {
        super(...arguments);
        this.queue = new KnowledgeBaseQueueService();
        this.resource = new KnowledgeBaseResourceService();
    }
    async filter(dto) {
        const res = await this.fetch('/knowledge-base/filter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        res.data = res.data.map(timeConverter);
        return res;
    }
    async create(userId) {
        const { data } = await this.fetch(`/knowledge-base?userId=${userId}`, {
            method: 'POST',
        });
        return timeConverter(data);
    }
    async remove(kbId) {
        await this.fetch(`/knowledge-base/${kbId}`, {
            method: 'DELETE',
        });
    }
    async patch(kbId, dto) {
        const { data } = await this.fetch(`/knowledge-base/${kbId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(data);
    }
}
export const knowledgeBaseService = new KnowledgeBaseService();
