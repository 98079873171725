"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePhone = void 0;
/**
 * 手机号验证
 * - 最长32位
 */
const validatePhone = (phone) => {
    if (phone.length > 32) {
        return 'phone is too long';
    }
    if (!/^\+?[0-9 ()]+\d$/.test(phone)) {
        return 'phone format is incorrect';
    }
    if (phone.startsWith('+86')) {
        return '+86 mobile phone numbers are temporarily not supported, please register via email';
    }
};
exports.validatePhone = validatePhone;
