export class IDBService {
    constructor({ name, version, upgradeHandler }) {
        this.name = name;
        this.version = version;
        this.upgradeHandler = upgradeHandler;
        this.idbPromise = new Promise((resolve, reject) => {
            const request = indexedDB.open(this.name, this.version);
            request.addEventListener('error', (ev) => {
                console.error('IDB Open Error', ev);
                reject(ev);
            });
            request.addEventListener('blocked', (ev) => {
                console.error('IDB Blocked', ev);
                reject(ev);
            });
            request.addEventListener('success', () => {
                console.debug('IDB Open Success');
                request.result.addEventListener('abort', (ev) => {
                    console.error('IDB Abort', ev);
                });
                request.result.addEventListener('error', (ev) => {
                    console.error('IDB Error', ev);
                });
                request.result.addEventListener('close', (ev) => {
                    console.debug('IDB Close', ev);
                });
                request.result.addEventListener('versionchange', (ev) => {
                    console.debug('IDB VersionChange', ev);
                });
                resolve(request.result);
            });
            request.addEventListener('upgradeneeded', (ev) => {
                try {
                    console.debug('IDB Upgradeneeded', ev);
                    this.upgradeHandler({ ev, request });
                }
                catch (err) {
                    reject(err);
                }
            });
        });
        this.idbPromise.then().catch(console.error);
    }
    async getIdb() {
        const idb = await this.idbPromise;
        return idb;
    }
    static handleIDBRequest(request) {
        return new Promise((resolve, reject) => {
            request.addEventListener('error', () => {
                reject(request.error);
            });
            request.addEventListener('success', () => {
                resolve(request.result);
            });
        });
    }
    async transaction(storeNames, mode) {
        const idb = await this.getIdb();
        return idb.transaction(storeNames, mode);
    }
    async objectStore(storeName, mode) {
        const tx = await this.transaction([storeName], mode);
        return tx.objectStore(storeName);
    }
    async index(storeName, mode, indexName) {
        const store = await this.objectStore(storeName, mode);
        return store.index(indexName);
    }
    async add(storeName, data) {
        const store = await this.objectStore(storeName, 'readwrite');
        return IDBService.handleIDBRequest(store.add(data));
    }
    async addMany(storeName, dataList) {
        const store = await this.objectStore(storeName, 'readwrite');
        const promises = dataList.map((data) => IDBService.handleIDBRequest(store.add(data)));
        return Promise.all(promises);
    }
    async put(storeName, data) {
        const store = await this.objectStore(storeName, 'readwrite');
        return IDBService.handleIDBRequest(store.put(data));
    }
    async putMany(storeName, dataList) {
        const store = await this.objectStore(storeName, 'readwrite');
        const promises = dataList.map((data) => IDBService.handleIDBRequest(store.put(data)));
        return Promise.all(promises);
    }
    async delete(storeName, query) {
        const store = await this.objectStore(storeName, 'readwrite');
        return IDBService.handleIDBRequest(store.delete(query));
    }
    async deleteMany(storeName, queries) {
        const store = await this.objectStore(storeName, 'readwrite');
        const promises = queries.map((query) => IDBService.handleIDBRequest(store.delete(query)));
        return Promise.all(promises);
    }
    async clear(storeName) {
        const store = await this.objectStore(storeName, 'readwrite');
        return IDBService.handleIDBRequest(store.clear());
    }
    async clearAll() {
        return IDBService.handleIDBRequest(indexedDB.deleteDatabase(this.name));
    }
    async clearIndex(storeName, indexName, query) {
        const index = await this.index(storeName, 'readwrite', indexName);
        const request = index.openCursor(query);
        return new Promise((resolve, reject) => {
            request.addEventListener('error', reject);
            request.addEventListener('success', () => {
                const cursor = request.result;
                if (cursor) {
                    cursor.delete();
                    cursor.continue();
                }
                else {
                    resolve();
                }
            });
        });
    }
    async get(storeName, query) {
        const store = await this.objectStore(storeName, 'readonly');
        return IDBService.handleIDBRequest(store.get(query));
    }
    async getAll(storeName, index, query) {
        const store = await this.objectStore(storeName, 'readonly');
        if (index) {
            const indexStore = store.index(index);
            return IDBService.handleIDBRequest(indexStore.getAll(query));
        }
        else {
            return IDBService.handleIDBRequest(store.getAll(query));
        }
    }
}
