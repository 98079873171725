import { BaseService } from './base.service.js';
class EmailService extends BaseService {
    async sendRegisterVerificationCode(dto) {
        return this.fetch('/email/verification/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async sendLoginVerificationCode(dto) {
        return this.fetch('/email/verification/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async sendResetVerificationCode(dto) {
        return this.fetch('/email/verification/reset', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async sendLinkVerificationCode(dto) {
        return this.fetch('/email/verification/link', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
}
export const emailService = new EmailService();
