"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcTextColor = exports.hslToHex = void 0;
/**
 * 颜色格式转换 hsl -> hex
 */
function hslToHex(h, s, l) {
    h /= 360;
    s /= 100;
    l /= 100;
    let r, g, b;
    if (s === 0) {
        r = g = b = l; // achromatic
    }
    else {
        const hue2rgb = (p, q, t) => {
            if (t < 0)
                t += 1;
            if (t > 1)
                t -= 1;
            if (t < 1 / 6)
                return p + (q - p) * 6 * t;
            if (t < 1 / 2)
                return q;
            if (t < 2 / 3)
                return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }
    const toHex = (x) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
exports.hslToHex = hslToHex;
const colorCache = {};
/**
 * 根据文本内容计算颜色
 */
function calcTextColor(text) {
    if (colorCache[text]) {
        return colorCache[text];
    }
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    // 限制亮度范围在20%到80%之间
    const lightness = Math.max(30, Math.min(70, (hash % 31) + 40));
    const color = hslToHex(hash % 360, 100, lightness);
    colorCache[text] = color;
    return color;
}
exports.calcTextColor = calcTextColor;
