"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultChatLayoutParam = void 0;
exports.defaultChatLayoutParam = {
    sidebar: {
        type: 'session',
        visible: true,
    },
    main: {
        header: {
            visible: true,
            return: {
                visible: true,
            },
            chatMode: {
                visible: true,
            },
            documents: {
                visible: true,
                upload: {
                    visible: true,
                },
            },
            setting: {
                visible: true,
            },
        },
        tool: {
            visible: true,
        },
        editor: {
            visible: true,
            action: {
                visible: true,
                clearContext: {
                    visible: true,
                },
                userQuestion: {
                    visible: true,
                },
            },
            send: {
                visible: true,
                times: {
                    visible: true,
                },
            },
        },
    },
};
