import { BaseService } from './base.service.js';
import { timeConverter } from '@ai-agent/util';
class FeedbackService extends BaseService {
    async create(dto) {
        const { data: feedback } = await this.fetch('/feedback', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(feedback);
    }
    async find(dto) {
        const { data: feedbacks } = await this.fetch('/feedback/find', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return feedbacks.map(timeConverter);
    }
    async patch(id, dto) {
        const { data: feedback } = await this.fetch(`/feedback/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(feedback);
    }
}
export const feedbackService = new FeedbackService();
