import { BaseService } from './base.service.js';
class FAQService extends BaseService {
    async search(dto) {
        return this.fetch('/faq/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async create(dto) {
        return this.fetch('/faq', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async remove(id) {
        return this.fetch(`/faq/${id}`, {
            method: 'DELETE',
        });
    }
    async update(id, dto) {
        return this.fetch(`/faq/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async autocomplete(userIds = [], q = '') {
        return this.fetch(`/faq/autocomplete?userIds=${userIds.join(',')}&q=${q}`);
    }
}
export const faqService = new FAQService();
