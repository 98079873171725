"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateAccount = void 0;
/**
 * 账号验证
 * - 最短4位
 * - 最长64位
 */
const validateAccount = (account) => {
    if (account.length < 4) {
        return 'account is too short';
    }
    if (account.length > 64) {
        return 'account is too long';
    }
};
exports.validateAccount = validateAccount;
