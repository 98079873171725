import { io } from 'socket.io-client';
import { Subject } from 'rxjs';
import { BaseService } from './base.service.js';
import { timeConverter } from '@ai-agent/util';
const CompletionsAPIKey = 'completionsAPI';
class ChatService extends BaseService {
    constructor() {
        super(...arguments);
        this.chatSubject = new Subject();
        this.completionSubject = new Subject();
        this.removeSubject = new Subject();
    }
    /**
     * 手动连接
     */
    async connect() {
        if (this.socket) {
            return;
        }
        const socket = io(this.BASE_URL, {
            transports: ['websocket'],
            auth: { Authorization: this.accessToken },
        });
        socket.on('connect', () => {
            console.debug('chat service connected');
        });
        socket.on('disconnect', () => {
            console.debug('chat service disconnected');
        });
        socket.on('connect_error', console.error);
        socket.on("chat" /* ChatEvent.chat */, (chatMessage) => {
            this.chatSubject.next(timeConverter(chatMessage));
        });
        socket.on("completions" /* ChatEvent.completions */, (chatMessage) => {
            this.completionSubject.next(timeConverter(chatMessage));
        });
        socket.on("remove" /* ChatEvent.remove */, (removedMessageIds) => {
            this.removeSubject.next(removedMessageIds);
        });
        window.addEventListener('pagehide', () => {
            socket.disconnect();
        });
        window.addEventListener('pageshow', () => {
            socket.connect();
        });
        this.socket = socket;
    }
    chat(message, callback) {
        const completionsAPI = localStorage.getItem(CompletionsAPIKey) || undefined;
        this.socket?.emit("chat" /* ChatEvent.chat */, { ...message, completionsAPI }, callback);
    }
    stop(dto) {
        this.socket?.emit("stop" /* ChatEvent.stop */, dto);
    }
    resend(dto, callback) {
        const completionsAPI = localStorage.getItem(CompletionsAPIKey) || undefined;
        this.socket?.emit("resend" /* ChatEvent.resend */, { ...dto, completionsAPI }, callback);
    }
}
export const chatService = new ChatService();
