import { assetService } from './asset.service.js';
class CloudflareR2Service {
    async put(input) {
        if (!input.Key) {
            throw new Error('Key is required');
        }
        const { uploadURL, uploadedURL } = await assetService.generateSignedUploadURL(input.Key);
        console.log(`fetch generated upload url: ${uploadURL}`);
        await fetch(uploadURL, {
            method: 'PUT',
            headers: {
                'Content-Type': input.ContentType || 'application/octet-stream',
            },
            body: input.Body,
        });
        return uploadedURL;
    }
}
export const cloudflareR2Service = new CloudflareR2Service();
