import { BaseService } from './base.service.js';
import { timeConverter } from '@ai-agent/util';
class PromptService extends BaseService {
    async getUserPrompt(userId) {
        const res = await this.fetch(`/prompt/${userId}`);
        return res.data.map(timeConverter);
    }
    async createUserPrompt(dto) {
        const res = await this.fetch('/prompt', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return timeConverter(res.data);
    }
    async updateUserPrompts(prompts) {
        return this.fetch('/prompt', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompts }),
        });
    }
    async deleteUserPrompt(promptId) {
        const res = await this.fetch(`/prompt/${promptId}`, {
            method: 'DELETE',
        });
        return timeConverter(res.data);
    }
    async getSessionPrompts(sessionId) {
        const res = await this.fetch(`/session/${sessionId}/prompts`);
        return res.data.map(timeConverter);
    }
    async getPrompts(ids) {
        const res = await this.fetch(`/prompt?ids=${ids.join(',')}`);
        return res.data.map(timeConverter);
    }
    async updatePrompt(id, dto) {
        const res = await this.fetch(`/prompt/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return res.data;
    }
}
export const promptService = new PromptService();
