"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Range = void 0;
class Range {
    start;
    end;
    excludeStart;
    excludeEnd;
    constructor(start, end, excludeStart, excludeEnd) {
        this.start = start;
        this.end = end;
        this.excludeStart = excludeStart;
        this.excludeEnd = excludeEnd;
    }
}
exports.Range = Range;
