"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateEmail = void 0;
/**
 * 邮箱验证
 * - 最长255位
 * - 邮箱格式正确
 */
const validateEmail = (email) => {
    if (email.length > 255) {
        return 'email is too long';
    }
    if (!/^.+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email)) {
        return 'email format is incorrect';
    }
};
exports.validateEmail = validateEmail;
