import { ComputerVisionClient } from '@azure/cognitiveservices-computervision';
import { ApiKeyCredentials } from '@azure/ms-rest-js';
class OCRService {
    constructor() {
        this.key = '039fb1378a5a458aa4578dac0bcffc7a';
        this.endpoint = 'https://typist-test-ai-ocr.cognitiveservices.azure.com/';
        this.computerVisionClient = new ComputerVisionClient(new ApiKeyCredentials({
            inHeader: { 'Ocp-Apim-Subscription-Key': this.key },
        }), this.endpoint);
        this.readTextFromFile = async (blob) => {
            const readInStreamResponse = await this.computerVisionClient.readInStream(blob);
            const operation = readInStreamResponse.operationLocation.split('/').slice(-1)[0];
            let getReadResultResponse = await this.computerVisionClient.getReadResult(operation);
            while (getReadResultResponse.status !== 'succeeded') {
                getReadResultResponse = await this.computerVisionClient.getReadResult(operation);
            }
            return (getReadResultResponse.analyzeResult?.readResults.map((r) => r.lines.map((l) => l.text).join('\n')).join('\n\n') ??
                '');
        };
    }
}
export const ocrService = new OCRService();
