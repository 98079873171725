"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pagination = void 0;
class Pagination {
    pageSize;
    pageIndex;
    totalSize;
    constructor(pageSize = 10, pageIndex = 0, totalSize = 0) {
        this.pageSize = pageSize;
        this.pageIndex = pageIndex;
        this.totalSize = totalSize;
    }
}
exports.Pagination = Pagination;
