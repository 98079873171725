"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractEXT = void 0;
const extractEXT = (filename) => {
    const names = filename.split('.');
    if (names.length < 2) {
        return 'bin';
    }
    return names.pop();
};
exports.extractEXT = extractEXT;
