import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './app.module.less';
import { AppBar, CssBaseline } from '@mui/material';
import { NavLink, Outlet } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { userService } from '@ai-agent/service';
import { subPages } from '@router';
import { Button } from 'antd';
export const App = () => (_jsxs(SnackbarProvider, { children: [_jsx(CssBaseline, {}), _jsxs("div", { className: styles.app, children: [_jsxs(AppBar, { className: styles.header, position: "relative", color: "transparent", enableColorOnDark: true, children: [_jsx("h5", { className: styles.title, children: "VinoAI CMS" }), _jsx("nav", { className: styles.nav, style: { flexGrow: 1 }, children: subPages.map((page) => (_jsx(NavLink, { to: page, children: _jsx(Button, { className: styles.link, type: "link", children: page }) }, page))) }), _jsx("div", { className: styles.actions, children: _jsx(Button, { onClick: userService.logout, children: "Logout" }) })] }), _jsx("div", { className: styles.container, children: _jsx("div", { className: styles.content, children: _jsx(Outlet, {}) }) })] })] }));
export default App;
