import { IDBService } from './idb.service.js';
class CacheService extends IDBService {
    constructor() {
        super({
            name: 'ai-agent-chat',
            version: 7,
            upgradeHandler: ({ ev, request }) => {
                // console.debug(ev, request);
                const idb = request.result;
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const transaction = request.transaction;
                /** 为已有store添加index
                 * const store = transaction.objectStore('message');
                 * store.createIndex('userId', 'userId');
                 */
                const { oldVersion } = ev;
                if (oldVersion < 1) {
                    if (!idb.objectStoreNames.contains('message')) {
                        const store = idb.createObjectStore('message', {
                            keyPath: 'id',
                        });
                        store.createIndex('sessionId', 'sessionId');
                    }
                    if (!idb.objectStoreNames.contains('session')) {
                        idb.createObjectStore('session', {
                            keyPath: 'id',
                        });
                    }
                }
                if (oldVersion < 2) {
                    if (!idb.objectStoreNames.contains('editor')) {
                        idb.createObjectStore('editor', {
                            keyPath: 'id',
                        });
                    }
                }
                if (oldVersion < 3) {
                    if (!idb.objectStoreNames.contains('prompt')) {
                        idb.createObjectStore('prompt', {
                            keyPath: 'id',
                        });
                    }
                }
                if (oldVersion < 4) {
                    if (!idb.objectStoreNames.contains('user')) {
                        idb.createObjectStore('user', {
                            keyPath: 'id',
                        });
                    }
                }
                // 与历史数据格式不兼容，专门升级一个版本用于清除历史数据
                if (oldVersion < 5) {
                    if (transaction) {
                        transaction.objectStore('message').clear();
                        transaction.objectStore('session').clear();
                        transaction.objectStore('editor').clear();
                        transaction.objectStore('prompt').clear();
                        transaction.objectStore('user').clear();
                    }
                }
                // message新增了uuid，但是本地缓存不会更新
                if (oldVersion < 6) {
                    if (transaction) {
                        transaction.objectStore('message').clear();
                    }
                }
                // 更新了cloudflare r2 custom domain, 需要浏览器缓存中的消息内容, 拉取最新消息
                if (oldVersion < 7) {
                    if (transaction) {
                        transaction.objectStore('message').clear();
                        transaction.objectStore('editor').clear();
                    }
                }
            },
        });
    }
}
export const cacheService = new CacheService();
