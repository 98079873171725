"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Order = void 0;
class Order {
    name;
    desc;
    constructor(name, desc) {
        this.name = name;
        this.desc = desc;
    }
}
exports.Order = Order;
