"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListResponse = void 0;
const data_response_1 = require("./data.response");
class ListResponse extends data_response_1.DataResponse {
    constructor(data = []) {
        super(data);
    }
}
exports.ListResponse = ListResponse;
