"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePassword = void 0;
/**
 * 密码验证
 * - 最短6位
 * - 最长64位
 * - 至少包含大小写数字中的两种
 */
const validatePassword = (password) => {
    if (password.length < 6) {
        return 'password is too short';
    }
    if (password.length > 64) {
        return 'password is too long';
    }
    let types = 0;
    if (/[a-z]/.test(password)) {
        types++;
    }
    if (/[A-Z]/.test(password)) {
        types++;
    }
    if (/\d/.test(password)) {
        types++;
    }
    if (types < 2) {
        return 'The password must contain at least two types of uppercase letters, lowercase letters, and numbers.';
    }
};
exports.validatePassword = validatePassword;
