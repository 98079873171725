"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortMessageSnippets = exports.sortMessages = void 0;
/** 消息列表排序规则
 * 1. 通过prevId将消息分为两组，没有prevId的部分为数组，有prevId的部分为Map
 * 2. 将没有prevId的部分根据ctime增序排列，并对排序后的数组进行遍历
 * 3. 根据消息ID到消息Map中查找，如果存在则追加到该消息后，并对追加的消息重复此操作
 */
const sortMessages = (messages) => {
    const normalMessages = messages.filter((s) => !s.prevId);
    const subMessages = messages.filter((s) => s.prevId);
    const subMessageMap = subMessages.reduce((total, msg) => ({
        ...total,
        [msg.prevId]: msg,
    }), {});
    const sortedNormalMessages = normalMessages.sort((l, r) => l.ctime.getTime() - r.ctime.getTime());
    for (let cursor = 0; cursor < sortedNormalMessages.length; cursor++) {
        let msg = sortedNormalMessages[cursor];
        let subMsg = subMessageMap[msg.id];
        while (subMsg) {
            sortedNormalMessages.splice(cursor + 1, 0, subMsg);
            cursor++;
            msg = subMsg;
            subMsg = subMessageMap[msg.id];
        }
    }
    return sortedNormalMessages;
};
exports.sortMessages = sortMessages;
// 对消息片段按照创建时间增序排序
const sortMessageSnippets = (snippets) => snippets.sort((l, r) => l.ctime.getTime() - r.ctime.getTime());
exports.sortMessageSnippets = sortMessageSnippets;
