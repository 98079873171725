import { jsx as _jsx } from "react/jsx-runtime";
import { userService } from '@ai-agent/service';
import App from '@app';
import { LazyPage } from '@component/lazy-page';
import { createBrowserRouter, redirect } from 'react-router-dom';
const routes = [
    {
        path: '/',
        element: _jsx(App, {}),
        loader: async () => {
            if (!userService.validAccessToken) {
                return redirect('/login');
            }
            return null;
        },
        children: [
            {
                path: '',
                loader: async () => redirect('user'),
            },
            {
                path: 'user',
                children: [
                    {
                        path: '',
                        element: _jsx(LazyPage, { page: "user" }),
                    },
                    {
                        path: 'card',
                        element: _jsx(LazyPage, { page: "user/card" }),
                    },
                    {
                        path: ':userId',
                        children: [
                            {
                                path: '',
                                element: _jsx(LazyPage, { page: "user/detail" }),
                            },
                            {
                                path: 'faq',
                                element: _jsx(LazyPage, { page: "faq" }),
                            },
                            {
                                path: 'function',
                                children: [
                                    {
                                        path: '',
                                        element: _jsx(LazyPage, { page: "function" }),
                                    },
                                    {
                                        path: ':id',
                                        element: _jsx(LazyPage, { page: "function/editor" }),
                                    },
                                    {
                                        path: 'create',
                                        element: _jsx(LazyPage, { page: "function/create" }),
                                    },
                                    {
                                        path: 'template',
                                        children: [
                                            {
                                                path: '',
                                                element: _jsx(LazyPage, { page: "function/template" }),
                                            },
                                            {
                                                path: 'create',
                                                element: _jsx(LazyPage, { page: "function/template/editor" }),
                                            },
                                            {
                                                path: ':templateId',
                                                element: _jsx(LazyPage, { page: "function/template/editor" }),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'asset',
                                element: _jsx(LazyPage, { page: "asset" }),
                            },
                            {
                                path: 'question',
                                element: _jsx(LazyPage, { page: "user/question" }),
                            },
                            {
                                path: 'rag',
                                children: [
                                    {
                                        path: '',
                                        element: _jsx(LazyPage, { page: "user/rag" }),
                                    },
                                    {
                                        path: ':kbId',
                                        children: [
                                            {
                                                path: 'resource',
                                                children: [
                                                    {
                                                        path: '',
                                                        element: _jsx(LazyPage, { page: "user/rag/knowledge-base/resource" }),
                                                    },
                                                    {
                                                        path: ':rId',
                                                        children: [
                                                            {
                                                                path: 'chunk',
                                                                element: _jsx(LazyPage, { page: "user/rag/knowledge-base/resource/chunk" }),
                                                            },
                                                            {
                                                                path: 'log',
                                                                element: _jsx(LazyPage, { page: "user/rag/knowledge-base/resource/log" }),
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'register',
                        element: _jsx(LazyPage, { page: "user/register" }),
                    },
                ],
            },
            {
                path: 'message',
                element: _jsx(LazyPage, { page: "message" }),
            },
            {
                path: 'session',
                children: [
                    {
                        path: '',
                        element: _jsx(LazyPage, { page: "session" }),
                    },
                    {
                        path: ':sessionId/message',
                        element: _jsx(LazyPage, { page: "session/message" }),
                    },
                    {
                        path: 'template',
                        element: _jsx(LazyPage, { page: "session/template" }),
                    },
                ],
            },
            {
                path: 'feedback',
                children: [
                    {
                        path: '',
                        element: _jsx(LazyPage, { page: "feedback" }),
                    },
                    {
                        path: ':feedbackId',
                        element: _jsx(LazyPage, { page: "feedback/editor" }),
                    },
                ],
            },
            {
                path: 'batch',
                children: [
                    {
                        path: '',
                        element: _jsx(LazyPage, { page: "batch" }),
                    },
                    {
                        path: 'send-message',
                        element: _jsx(LazyPage, { page: "batch/send-message" }),
                    },
                    {
                        path: 'add-friend',
                        element: _jsx(LazyPage, { page: "batch/add-friend" }),
                    },
                ],
            },
            {
                path: 'prompt/form/:promptId',
                element: _jsx(LazyPage, { page: "user/detail/prompt/form/editor" }),
            },
        ],
    },
    {
        path: '/login',
        element: _jsx(LazyPage, { page: "login" }),
    },
    {
        path: '*',
        loader: async () => redirect('/'),
    },
];
export const router = createBrowserRouter(routes);
export const subPages = (() => {
    const app = routes.find((route) => route.path === '/');
    if (!app) {
        return [];
    }
    return (app.children ?? [])
        .filter((child) => !!child.path && !child.path.includes('/'))
        .map((child) => child.path ?? '');
})();
