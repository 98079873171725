"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateTitle = void 0;
/**
 * Title 验证
 *
 * - 最长32位
 */
const validateTitle = (title) => {
    if (title.length > 32) {
        return 'Title is too long';
    }
};
exports.validateTitle = validateTitle;
