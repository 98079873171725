"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timeConverter = void 0;
/**
 * 将一个对象中的时间从字符串转为Date对象
 */
const timeConverter = (session) => ({
    ...session,
    ctime: new Date(session.ctime),
    utime: new Date(session.utime),
    dtime: session.dtime ? new Date(session.dtime) : session.dtime,
    indexTime: session.indexTime ? new Date(session.indexTime) : session.indexTime,
});
exports.timeConverter = timeConverter;
