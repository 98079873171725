"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseException = void 0;
class BaseException extends Error {
    code;
    constructor(message = 'unknown exception', code = -1) {
        super(message);
        this.code = code;
    }
}
exports.BaseException = BaseException;
