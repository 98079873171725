"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateOrg = void 0;
/**
 * Company/Organization验证
 *
 * - 最长255位
 */
const validateOrg = (org) => {
    if (org.length > 255) {
        return 'Company/Organization is too long';
    }
};
exports.validateOrg = validateOrg;
