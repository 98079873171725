"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uuid = void 0;
/**
 * 生成一个uuid
 * @returns 36位的uuid
 */
const uuid = () => {
    const url = URL.createObjectURL(new Blob());
    const id = url.slice(-36);
    URL.revokeObjectURL(url);
    return id;
};
exports.uuid = uuid;
