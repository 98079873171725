"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cloneDeep = exports.mergeDeep = exports.isObject = void 0;
/**
 * Checks if the given item is an object and not an array.
 * @param item - The item to check.
 * @returns {boolean} - True if the item is an object and not an array, otherwise false.
 */
function isObject(item) {
    return item !== null && typeof item === 'object' && !Array.isArray(item);
}
exports.isObject = isObject;
/**
 * Deeply merges two or more objects.
 * @param target - The target object to merge into.
 * @param sources - The source objects to merge from.
 * @returns {T} - The merged target object.
 */
function mergeDeep(target, ...sources) {
    if (!sources.length)
        return target;
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (const key of Reflect.ownKeys(source)) {
            const sourceValue = source[key];
            const targetValue = target[key];
            if (isObject(sourceValue) && isObject(targetValue)) {
                mergeDeep(targetValue, sourceValue);
            }
            else {
                Object.assign(target, { [key]: sourceValue });
            }
        }
    }
    return mergeDeep(target, ...sources);
}
exports.mergeDeep = mergeDeep;
/**
 * Deeply clones an object.
 * @param source - The source object to clone.
 * @returns {T} - The cloned object.
 */
function cloneDeep(source) {
    if (!isObject(source)) {
        return source;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clone = Array.isArray(source) ? [] : {};
    for (const key of Reflect.ownKeys(source)) {
        const value = source[key];
        if (isObject(value)) {
            clone[key] = cloneDeep(value);
        }
        else {
            clone[key] = value;
        }
    }
    return clone;
}
exports.cloneDeep = cloneDeep;
