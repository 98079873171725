"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateNickname = void 0;
/**
 * Nickname验证
 *
 * - 最长256位
 */
const validateNickname = (nickname) => {
    if (nickname.length > 256) {
        return 'nickname is too long';
    }
};
exports.validateNickname = validateNickname;
