"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseJWT = exports.sha256 = void 0;
// 通用的 SHA-256 函数，可在前后端通用
async function sha256(message) {
    if (typeof window !== 'undefined' && window.crypto && window.crypto.subtle) {
        // 在支持 Web Crypto API 的环境（如浏览器）下使用
        const msgBuffer = new TextEncoder().encode(message);
        const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
        return hashHex;
    }
    else if (typeof require !== 'undefined') {
        // 在 Node.js 等环境下使用 Node.js 的 crypto 模块
        const crypto = await import('crypto');
        return crypto.createHash('sha256').update(message).digest('hex');
    }
    else {
        throw new Error('SHA-256 is not supported in this environment');
    }
}
exports.sha256 = sha256;
const parseJWT = (jwt = '') => {
    if (!jwt) {
        console.error(`Invalid JWT: ${jwt}`);
        return;
    }
    const [, payload] = jwt.split('.');
    try {
        return JSON.parse(atob(payload));
    }
    catch (err) {
        console.error(`Error parsing JWT: ${jwt}`);
        return;
    }
};
exports.parseJWT = parseJWT;
