import { BaseService } from './base.service.js';
class AssetService extends BaseService {
    async create(dto) {
        return this.fetch('/asset', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async remove(id) {
        return this.fetch(`/asset/${id}`, {
            method: 'DELETE',
        });
    }
    async findOne(id) {
        return this.fetch(`/asset/${id}`);
    }
    async findMany(type, userId) {
        return this.fetch(`/asset?type=${type}&userId=${userId}`);
    }
    async generateSignedUploadURL(key) {
        const res = await this.fetch(`/asset/generate-signed-upload-url?key=${encodeURIComponent(key)}`);
        return res.data;
    }
}
export const assetService = new AssetService();
