"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataResponse = void 0;
const base_response_1 = require("./base.response");
class DataResponse extends base_response_1.BaseResponse {
    data;
    constructor(data) {
        super();
        this.data = data;
    }
}
exports.DataResponse = DataResponse;
