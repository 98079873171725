import { BaseService } from './base.service.js';
class UserQuestionValueService extends BaseService {
    async filter(dto) {
        return this.fetch('/user/question/value/filter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async create(dto) {
        const { data } = await this.fetch('/user/question/value', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return data;
    }
    async update(dto) {
        const { data } = await this.fetch('/user/question/value', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        return data;
    }
    async remove(id) {
        const { data } = await this.fetch(`/user/question/value/${id}`, {
            method: 'DELETE',
        });
        return data;
    }
    async search(dto) {
        return this.fetch('/user/question/value/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
}
export const userQuestionValueService = new UserQuestionValueService();
