import { BaseService } from './base.service.js';
class FunctionService extends BaseService {
    async findAll(userId) {
        return this.fetch(`/function?userId=${userId}`);
    }
    async findOne(id) {
        return this.fetch(`/function/${id}`);
    }
    async create(dto) {
        return this.fetch('/function', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async deleteOne(id) {
        return this.fetch(`/function/${id}`, {
            method: 'DELETE',
        });
    }
    async updateOne(id, dto) {
        return this.fetch(`/function/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async createParameter(id, dto) {
        return this.fetch(`/function/${id}/parameter`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async deleteParameter(id) {
        return this.fetch(`/function/parameter/${id}`, {
            method: 'DELETE',
        });
    }
    async updateParameter(id, dto) {
        return this.fetch(`/function/parameter/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async updateParameterOrder(id, order) {
        return this.fetch(`/function/parameter/${id}/order?order=${order}`, {
            method: 'PATCH',
        });
    }
    async createExample(id, dto) {
        return this.fetch(`/function/${id}/example`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async deleteExample(id) {
        return this.fetch(`/function/example/${id}`, {
            method: 'DELETE',
        });
    }
    async updateExample(id, dto) {
        return this.fetch(`/function/example/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async createTemplate(dto) {
        return this.fetch('/function/template', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async deleteTemplate(id) {
        return this.fetch(`/function/template/${id}`, {
            method: 'DELETE',
        });
    }
    async updateTemplate(id, dto) {
        return this.fetch(`/function/template/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async findTemplateById(id) {
        return this.fetch(`/function/template/${id}`);
    }
    async findTemplateByUserId(id) {
        return this.fetch(`/function/template/by-user/${id}`);
    }
}
export const functionService = new FunctionService();
