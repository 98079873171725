"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INITIAL_SESSION_CONTEXT_PROTOCOL = exports.parseSessionContext = void 0;
const parseSessionContext = (context) => {
    try {
        return JSON.parse(context ?? '{}');
    }
    catch (err) {
        return null;
    }
};
exports.parseSessionContext = parseSessionContext;
/**
 * protocol writing 默认会话Context
 */
exports.INITIAL_SESSION_CONTEXT_PROTOCOL = {
    type: 'protocol',
    title_or_topic: '',
    indication: '',
    drug: '',
    objectives: '',
    endpoints: '',
    inclusion_criteria: '',
    exclusion_criteria: '',
};
