import { timeConverter } from '@ai-agent/util';
import { BaseService } from './base.service.js';
class UserService extends BaseService {
    async login(dto) {
        await this.fetch('/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async loginWithEmail(dto) {
        await this.fetch('/auth/login/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async loginWithPhone(dto) {
        await this.fetch('/auth/login/phone', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async linkEmail(dto) {
        await this.fetch('/auth/link/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async linkPhone(dto) {
        await this.fetch('/auth/link/phone', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async context() {
        return this.fetch('/user/context');
    }
    async profile(userId) {
        return (await this.fetch(`/user/profile/${userId}`)).data;
    }
    async getAllUsers() {
        return (await this.fetch('/user/all')).data;
    }
    async register(dto) {
        await this.fetch('/auth/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async registerWithEmail(dto) {
        await this.fetch('/auth/register/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async registerWithPhone(dto) {
        await this.fetch('/auth/register/phone', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async patchUser(dto) {
        return this.fetch('/user', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async getUsers(ids) {
        return (await this.fetch(`/user?ids=${ids.join(',')}`)).data;
    }
    async getFriends(id) {
        return this.fetch(`/user/${id}/friend`);
    }
    async addFriend(id, friendId) {
        return this.fetch(`/user/${id}/friend/${friendId}`, {
            method: 'PATCH',
        });
    }
    async removeFriend(id, friendId) {
        return this.fetch(`/user/${id}/friend/${friendId}`, {
            method: 'DELETE',
        });
    }
    async logout() {
        localStorage.clear();
        // cacheService.clearAll();
        location.reload();
    }
    async filter(dto) {
        const res = await this.fetch('/user/filter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
        res.data = res.data.map(timeConverter);
        return res;
    }
    async bulkAddFriend(dto) {
        return this.fetch('/user/bulk-add-friend', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async getEnabledTokens(userId) {
        const { data } = await this.fetch(`/user/jwt/${userId}`);
        return data;
    }
    async disableToken(token) {
        return this.fetch(`/user/jwt/${token}`, {
            method: 'DELETE',
        });
    }
    async resetPassword(dto) {
        return this.fetch('/auth/reset/password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dto),
        });
    }
    async generateTextAvatar(text, fontColor = '', bgColor = '') {
        const res = await this.fetch(`/user/avatar/text?text=${encodeURIComponent(text)}&fontColor=${encodeURIComponent(fontColor)}&bgColor=${encodeURIComponent(bgColor)}`, {
            method: 'GET',
        });
        return res.data;
    }
    async disable(userId) {
        return this.fetch(`/user/${userId}/disable`, {
            method: 'POST',
        });
    }
    async enable(userId) {
        return this.fetch(`/user/${userId}/enable`, {
            method: 'POST',
        });
    }
    async getLastLoginTime(userIds) {
        const { data } = await this.fetch(`/user/last-login?ids=${userIds.join(',')}`);
        return data;
    }
}
export const userService = new UserService();
