"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationResponse = void 0;
const list_response_1 = require("./list.response");
class PaginationResponse extends list_response_1.ListResponse {
    pagination;
    constructor(pagination, data = []) {
        super(data);
        this.pagination = pagination;
    }
}
exports.PaginationResponse = PaginationResponse;
