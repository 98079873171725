"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseResponse = void 0;
class BaseResponse {
    code;
    message;
    constructor(code = 0, message = 'ok') {
        this.code = code;
        this.message = message;
    }
}
exports.BaseResponse = BaseResponse;
