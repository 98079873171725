"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateWeixin = void 0;
/**
 * 微信验证
 * - 最长32位
 */
const validateWeixin = (weixin) => {
    if (weixin.length > 32) {
        return 'weixin is too long';
    }
};
exports.validateWeixin = validateWeixin;
