import { jsx as _jsx } from "react/jsx-runtime";
import './index.less';
import { router } from '@router';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
const entrypoint = document.getElementById('entrypoint');
(() => {
    if (!entrypoint) {
        const tip = document.createElement('strong');
        tip.textContent = 'entrypoint not found';
        tip.style.color = 'red';
        document.body.appendChild(tip);
        return;
    }
    const root = createRoot(entrypoint);
    root.render(_jsx(StrictMode, { children: _jsx(RouterProvider, { router: router }) }));
})();
